import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '../../../../routes.enum';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import { OrderService } from '../../order.service';
import { PrinthouseOrder } from '../../models/printhouse-order.model';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import DrawingThumbnailList from '@shared/modules/products/components/drawing-thumbnail-list/drawing-thumbnail-list.component.vue';
import ProductPreviewDrawingThumbnail from '@shared/modules/products/components/product-preview-drawing-thumbnail/product-preview-drawing-thumbnail.component.vue';
import { BaseConfig } from '@shared/base-config';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import ProductPreviewDrawing from '@shared/modules/products/components/product-preview-drawing/product-preview-drawing.component.vue';
import { RequestService } from '@shared/request/request.service';
// @ts-ignore
import { saveAs } from 'file-saver';

@Component({
    components: {
        Breadcrumbs,
        ProductSection,
        DrawingThumbnailList,
        ProductPreviewDrawingThumbnail,
        ProductPreviewDrawing
    }
})
export default class OrderDetails extends Vue {

    order: PrinthouseOrder | null = null;
    breadcrumbs: BreadcrumbHistoryItem[] = [];
    heightOfPreviewWindow = 0;
    readonly FluteDirection = FluteDirection;
    downloadLoading = false;
    selectedThumbnailUrl: string | null = null;
    is3DSelected = true;
    readonly Routes = Routes;
    private readonly orderService = new OrderService();

    activeTab: number | null = null;
    readonly scrollOptions = {
        rail: {
            background: '#f5f5f5',
            opacity: 1,
            size: '8px',
            specifyBorderRadius: false,
            gutterOfEnds: null,
            gutterOfSide: '2px',
            keepShow: true
        },
        bar: {
            showDelay: 500,
            onlyShowBarOnScroll: true,
            keepShow: true,
            background: '#c1c1c1',
            opacity: 1,
            hoverStyle: false,
            specifyBorderRadius: false,
            minSize: 0,
            size: '8px',
            disable: false
        },
        scrollPanel: {
            scrollingX: false
        }
    };

    async created() {
        const order = await this.orderService.getOrder(this.$route.params.id);
        await this.setPrefixes(order);
        this.order = order;
        this.initBreadcrumbs();
    }

    handlePreviewWindowResize() {
        this.heightOfPreviewWindow = window.innerHeight - 224;
        return this.heightOfPreviewWindow;
    }

    mounted() {
        window.addEventListener('resize', this.handlePreviewWindowResize);
        this.handlePreviewWindowResize();
    }

    destroyed() {
        window.removeEventListener('resize', this.handlePreviewWindowResize);
    }

    async download() {
        this.downloadLoading = true;
        const order = this.order as PrinthouseOrder;
        const resp = await RequestService.get('api/v1/files/' + order.evdUrl, null,
            {apiPath: BaseConfig.apiPortal, responseType: 'blob'});
        saveAs(resp, 'order.evd');
        this.downloadLoading = false;
    }

    private async setPrefixes(order: PrinthouseOrder) {
        for (let i = 0; i < order.oneUpsPreviewUrls.length; i++) {
            order.oneUpsPreviewUrls[i] = `${BaseConfig.apiPortal}api/v1/files/${encodeURI(order.oneUpsPreviewUrls[i])}?t=${Date.now()}`;
        }

        if (order.preview3DUrl) {
            const headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            };
            const {value: token} = await RequestService.post('api/v1/files/short-lived-token', {token: true}, {
                headers,
                apiPath: BaseConfig.apiPortal
            });
            order.iframe3DUrl = `3D.html?url=${encodeURIComponent(BaseConfig.apiPortal + 'api/v1/files/' + order.daeUrl)}?token=${token}&Animations3D=${encodeURIComponent(order.colladaAnimations)}`;
            order.preview3DUrl = `${BaseConfig.apiPortal}api/v1/files/${encodeURI(order.preview3DUrl)}?t=${Date.now()}`;
        }
    }

    selectedThumbnailChange(url: string, index: number, is3D?: boolean) {
        this.selectedThumbnailUrl = url;
        this.is3DSelected = false;
        this.activeTab = index;
        if (is3D) {
            this.is3DSelected = true;
        }
    }

    private initBreadcrumbs() {

        this.breadcrumbs = [
            {
                text: 'orders.breadcrumbs.orders',
                disabled: false,
                to: {
                    name: Routes.OrderList
                }
            },
            {
                plainText: (this.order as PrinthouseOrder).customerEmail,
                disabled: true
            }
        ];
    }
}
