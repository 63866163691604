import { Component, Vue, Watch } from 'vue-property-decorator';
import { Routes } from '../../../../routes.enum';
import SubNavigation from '@shared/components/sub-navigation/sub-navigation.component.vue';
import { SubNavigationItem } from '@shared/components/sub-navigation/sub-navigation-item.interface';

@Component({
    components: {
        SubNavigation
    }
})
export default class Profile extends Vue {
    readonly items: SubNavigationItem[] = [
        {
            text: 'profile.tabs.companyDetails',
            route: Routes.CompanyDetails
        },
        {
            text: 'profile.tabs.contactPerson',
            route: Routes.ContactPerson
        },
        {
            text: 'profile.tabs.theme',
            route: Routes.Theme
        },
    ];
}
