import { Component, Vue } from 'vue-property-decorator';
import { OrderService } from '../../order.service';
import { OrderListItem } from '../../models/order-list-item.model';
import { UtilService } from '@shared/utils/util.service';
import { Routes } from '../../../../routes.enum';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import Pagination from '@shared/components/pagination/pagination.component.vue';

@Component({
    components: {
      Breadcrumbs,
      Pagination
    }
})

export default class OrderList extends Vue {
    page = 1;
    tableLoading = true;
    itemsPerPage = 10;
    totalPages = 0;
    searchLabel = this.$i18n.t('common.search');
    search = '';
    breadcrumbs: BreadcrumbHistoryItem[] = [];
    orderService = new OrderService();
    orders: OrderListItem[] = [];
    totalCount = 0;
    tableHeaders = [
        {
            text: this.$i18n.t('orders.list.table.date'),
            value: 'created',
        },
        {
            text: this.$i18n.t('orders.list.table.company'),
            value: 'customerCompanyName'
        },
        {
            text: this.$i18n.t('orders.list.table.customer'),
            value: 'customerName'
        },
        {
            text: this.$i18n.t('orders.list.table.customerEmail'),
            value: 'customerEmail'
        },
    ];

    readonly Routes = Routes;

    created() {
        this.initBreadcrumbs();

        if (!UtilService.isEmptyOrNull(this.$route.query.page)) {
            this.page = +this.$route.query.page;
        }
      
        if (!UtilService.isEmptyOrNull(this.$route.query.size)) {
            this.itemsPerPage = +this.$route.query.size;
        }
      
        if (!UtilService.isEmptyOrNull(this.$route.query.name)) {
            this.search = this.$route.query.name as string;
        }

        this.getOrders();
    }

    async getOrders() {
        if (this.$store.getters['auth/isAuthenticated']) {
            const data = this.getSearchData();
            this.tableLoading = true;

            const { orders, totalCount, totalNumPages, pageSize, startPage } = await this.orderService.getOrders(this.page, 10, data);

            this.tableLoading = false;

            this.orders = orders;
            this.totalCount = totalCount;
            this.itemsPerPage = pageSize;
            this.page = startPage + 1;
            this.totalPages = totalNumPages;
        }
    }

    formatDate(date: string) {
        const now = new Date(date);
        return UtilService.formatDate(now, 'yyyy-MM-dd HH:mm');
    }

    goToOrderDetails(order: OrderListItem) {
        this.$router.push({name: Routes.OrderDetails, params: {id: order.uuid}});
    }

    updateSearch() {
        this.getOrders();
        this.updateUrl();
    }

    onPageChange(page: number) {
        this.page = page;
        this.updateSearch();
    }

    private getSearchData() {
        const data: any = {
          page: this.page,
          size: this.itemsPerPage,
        };
    
        if (!UtilService.isEmptyOrNull(this.search)) {
            data.keywords = this.search;
        }
    
        return data;
    }

    private updateUrl() {
        this.$router.replace({ name: Routes.OrderList, query: this.getSearchData() as any });
    }

    private initBreadcrumbs() {
        this.breadcrumbs = [
          {
            text: 'orders.breadcrumbs.orders',
            disabled: false
          }
        ];
    }
}
