export enum Routes {
    OrderList = 'orderList',
    OrderDetails = 'orderDetails',
    CustomerList = 'customerList',
    CustomerDetails = 'customerDetails',
    CompanyDetails = 'companyDetails',
    ContactPerson = 'contactPerson',
    UserLogin = 'userLogin',
    UserForgotten = 'userForgotten',
    Profile = 'profile',
    Theme = 'theme',
    OrderRoot = 'orderRoot',
    CustomerRoot = 'customerRoot',
    UserSetPassword = 'userSetPassword',
}
