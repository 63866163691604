import { RequestService } from '@shared/request/request.service';
import { Config } from '../../config';
import { OrderListItem } from './models/order-list-item.model';
import { PrinthouseOrder } from './models/printhouse-order.model';

export class OrderService {
    getOrders(page: number, itemsPerPage: number, params: any): Promise<{orders: OrderListItem[]; totalCount: number, pageSize: number, totalNumPages: number, startPage: number }> {
        return RequestService.get(`api/v1/printhouse/orders/${page - 1}/${itemsPerPage}`, params, {apiPath: Config.apiPortal})
            .then(resp => {
                return {
                    orders: resp.orders,
                    totalCount: resp.totalSize,
                    pageSize: resp.pageSize,
                    startPage: resp.page,
                    totalNumPages: resp.totalNumPages
                };
            });
    }

    getOrder(id: string): Promise<PrinthouseOrder> {
        return RequestService.get(`api/v1/printhouse/order/${id}`, null, {apiPath: Config.apiPortal});
    }
}
