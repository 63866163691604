import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Routes } from './routes.enum';
import { loadLanguageAsync } from '@shared/plugins/i18n';
import OrderDetails from './modules/orders/pages/order-details/order-details.component.vue';
import OrderList from './modules/orders/pages/order-list/order-list.component.vue';
import CustomerList from './modules/customers/pages/customer-list/customer-list.component.vue';
import CustomerDetails from './modules/customers/pages/customer-details/customer-details.component.vue';
import Profile from './modules/profile/pages/profile/profile.component.vue';
import ProfileContactPerson from './modules/profile/pages/profile-contact-person/profle-contact-person.component.vue';
import ProfileCompanyDetails from './modules/profile/pages/profile-company-details/profile-company-details.component.vue';
import ProfileTheme from './modules/profile/pages/profile-theme/profile-theme.component.vue';
import UserLogin from './modules/users/pages/user-login/user-login.component.vue';
import UserForgotten from '@shared/components/user-forgotten/user-forgotten.component.vue';
import store from './store/store';
import PageContainer from '@shared/components/page-container/page-container.component.vue';
import Tos from '@shared/components/tos/tos.component.vue';
import Privacy from '@shared/components/privacy/privacy.component.vue';
import UserSetPassword from './modules/users/pages/user-set-password/user-set-password.component.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/order',
        component: PageContainer,
        name: Routes.OrderRoot,
        redirect: {name: Routes.OrderList},
        children: [
            {path: 'list', name: Routes.OrderList, component: OrderList},
            {path: ':id', name: Routes.OrderDetails, component: OrderDetails},
        ]
    },
    {
        path: '/customer',
        component: PageContainer,
        name: Routes.CustomerRoot,
        redirect: {name: Routes.CustomerList},
        children: [
            {path: 'list', name: Routes.CustomerList, component: CustomerList},
            {
                path: ':id',
                name: Routes.CustomerDetails,
                component: CustomerDetails,
            },
        ]
    },
    {path: '/tos', name: 'tos', component: Tos},
    {path: '/privacy', name: 'privacy', component: Privacy},
    {path: '/user/login', name: Routes.UserLogin, component: UserLogin},
    {path: '/user/set-password/:token', name: Routes.UserSetPassword, component: UserSetPassword},
    {path: '/user/forgotten', name: Routes.UserForgotten, component: UserForgotten},
    {
        path: '/profile', name: Routes.Profile, component: Profile,
        children: [
            {
                path: 'company-details',
                name: Routes.CompanyDetails,
                component: ProfileCompanyDetails,
            },
            {
                path: 'contact-person',
                name: Routes.ContactPerson,
                component: ProfileContactPerson
            },
            {
                path: 'theme',
                name: Routes.Theme,
                component: ProfileTheme
            },
        ]
    },
    {path: '*', redirect: {name: Routes.OrderList}},
];

// Create a new router
const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});

router.beforeEach((to, from, next) => {
    if (!store.getters['auth/isAuthenticated'] && ![Routes.UserLogin, Routes.UserForgotten, 'tos', 'privacy', Routes.UserSetPassword].includes(to.name as Routes)) {
        next({name: Routes.UserLogin, query: {redirectTo: to.path}});
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    loadLanguageAsync(store.getters['auth/languageWithDefault']).then(() => next());
});

router.beforeEach(async (to, from, next) => {
    if (store.getters['auth/isAuthenticated']) {
        await store.dispatch('getPrinthouse');
    }
    next();
});

export default router;
