import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { Person } from './person.model';
import { Config } from '../../../config';

export class Printhouse {
    uuid: string;
    name: string;
    theme: any;
    units: Units;
    userProfile: Person;
    logoUrl: string | null;

    constructor({uuid, name, theme, measurementUnit, userProfile, logoUrl}: any) {
        this.uuid = uuid;
        this.name = name;
        this.theme = theme;
        this.units = measurementUnit;
        this.userProfile = new Person(userProfile);
        this.logoUrl = logoUrl && `${Config.apiPortal}api/v1/files/${logoUrl}`;

        if (!this.theme) {
            this.theme = {};
            this.theme.headerFooterBkgColor = null;
            this.theme.headerFooterTextColor = null;
            this.theme.buttonBkgColor = null;
            this.theme.buttonTextColor = null;
            this.theme.linkColor = null;
            this.theme.borderShadowColor = null;
            this.theme.borderShadowType = null;
        }
    }
}
