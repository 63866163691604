






















import Header from '@/core/header/header.component.vue';
import Main from '@/core/main/main.component.vue';
import Footer from '@shared/components/footer/footer.component.vue';
import Notification from '@shared/components/notification/notification.component.vue';
import '@shared/directives/select-text-onload.directive';
import {Config} from './config';
import {Routes} from './routes.enum';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {UtilService} from '@shared/utils/util.service';
import {ThemeBorderShadowType} from '@shared/enums/theme-border-shadow-type.enum';
import {loadLanguageAsync} from '@shared/plugins/i18n';
import ConfirmModal from '@shared/components/confirm-modal/confirm-modal.component.vue';
import {Printhouse} from './modules/profile/models/printhouse.model';

@Component({
  provide() {
    return {
      config: Config
    };
  },
  components: {
    Header,
    Main,
    Footer,
    Notification,
    ConfirmModal
  }
})
export default class App extends Vue {

  loadingOverlayVisible = false;

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'auth/logout') {
        this.$store.commit('setPrinthouse', null);
        const {status, code} = mutation.payload;
        if (status === 401) {
          this.$router.push({name: Routes.UserLogin, query: {redirectTo: location.hash.replace('#', '')}});
        } else if (status === 403) {
          this.$store.dispatch('notification/showError', {text: this.$i18n.t(`errors.${code}`), timeout: 0});
        } else {
          this.$router.push({name: Routes.UserLogin});
        }
      }
    });
  }

  get styles() {
    const styles = {
      '--header-bg-color': this.printhouse?.theme.headerFooterBkgColor || '#1B2958',
      '--header-text-color': this.printhouse?.theme.headerFooterTextColor || '#ffffff',
      '--button-bg-color': this.printhouse?.theme.buttonBkgColor || '#4494e5',
      '--button-text-color': this.printhouse?.theme.buttonTextColor || '#ffffff',
      '--link-color': this.printhouse?.theme.linkColor || '#4494e5',
      '--header-nav-hover-bg': this.printhouse?.theme.headerFooterBkgColor ?
          UtilService.lightenDarkenColor(this.printhouse?.theme.headerFooterBkgColor, 20) : '#2A418C',
      '--header-border-bottom-color': 'transparent',
      '--header-box-shadow': 'none'
    };

    if (this.printhouse?.theme.borderShadowType === ThemeBorderShadowType.Border) {
      styles['--header-border-bottom-color'] = this.printhouse?.theme.borderShadowColor;
    } else if (this.printhouse?.theme.borderShadowType === ThemeBorderShadowType.Shadow) {
      styles['--header-box-shadow'] = `0 5px 10px ${this.printhouse?.theme.borderShadowColor}`;
    }

    return styles;
  }

  get printhouse(): Printhouse {
    return this.$store.getters.printhouse;
  }

  get language(): string {
    return this.$store.getters['auth/languageWithDefault'];
  }

  @Watch('language')
  onLangChange(lang: string) {
    loadLanguageAsync(lang);
  }
}
