import Vue from 'vue';
import App from './App.vue';
import vuetify from '../../../shared/plugins/vuetify';
import store from './store/store';
import router from './router';
import vuescroll from 'vuescroll';
import { i18n } from '@shared/plugins/i18n';
import numberRoundFilter from '@shared/filters/number-round.filter';
import drawingUrl from '@shared/filters/drawing-url.filter';
import '@shared/directives/numeric.directive';
import url from '@shared/filters/url.filter';
import '../../../node_modules/@mdi/font/css/materialdesignicons.css';

// Vue.prototype.$http = axios;

Vue.config.productionTip = false;
Vue.prototype.$log = console.log;

Vue.use(vuescroll);

Vue.filter('numberRound', numberRoundFilter);
Vue.filter('drawingUrl', drawingUrl);
Vue.filter('url', url);

new Vue({
  vuetify,
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app');
