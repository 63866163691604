import { RequestService } from '@shared/request/request.service';
import { Config } from '../../config';
import { CustomerListItem } from './models/customer-list-item.model';
import { Customer } from './models/customer.model';

export class CustomerService {
    static getCustomers(page: number, itemsPerPage: number, params: any): Promise<{ customers: CustomerListItem[]; totalCount: number, totalNumPages: number, pageSize: number, startPage: number }> {
        return RequestService.get(`api/v1/printhouse/customers/${page - 1}/${itemsPerPage}`, params, {apiPath: Config.apiPortal})
            .then(resp => {
                return {
                    customers: resp.customers,
                    totalCount: resp.totalSize,
                    pageSize: resp.pageSize,
                    startPage: resp.page,
                    totalNumPages: resp.totalNumPages
                };
            });
    }

    static getCustomer(email: string): Promise<Customer> {
        return RequestService.get(`api/v1/printhouse/customer`, {email}, {apiPath: Config.apiPortal});
    }
}
