import { Component, Vue, Watch } from 'vue-property-decorator';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { Person } from '../../models/person.model';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import ChangePasswordModal from '@shared/modules/profile/components/change-password-modal/change-password-modal.component.vue';
import { SelectFieldItem } from '@shared/components/select-field/select-field-item.interface';
import { BaseConfig } from '@shared/base-config';
import SelectField from '@shared/components/select-field/select-field.component.vue';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import { Printhouse } from '../../models/printhouse.model';

@Component({
    components: {
        CustomInput,
        ChangePasswordModal,
        LoadingButton,
        SelectField,
        FormErrorMessage
    }
})
export default class ProfileContactPerson extends Vue {
    dialog = false;
    name = '';
    email = '';
    phone = '';
    language = '';
    errorMessage: string | null = null;

    readonly languages: SelectFieldItem[] = Object.keys(BaseConfig.availableLanguages).map((l: any) => {
        return {
            value: l,
            text: (BaseConfig.availableLanguages as any)[l],
        };
    });

    async updatePerson(stopLoading: FunctionConstructor) {
        this.errorMessage = null;
        if (this.validate()) {
           try {
               const data = new Person({
                   name: this.name,
                   email: this.email,
                   phone: this.phone,
                   locale: this.language,
                   guid: this.printhouse.userProfile.guid
               });
               await this.$store.dispatch('updatePersonDetails', data);
               this.$store.dispatch('notification/showSuccess',
                   {text: this.$i18n.t('messages.saved')});
           } catch (e) {
               this.errorMessage = `errors.${e.code}`;
           }
        }

        stopLoading();
    }

    get printhouse() {
        return this.$store.getters.printhouse;
    }

    get isSuperadmin(): boolean {
        return this.$store.getters['auth/organizationUuid'];
    }

    @Watch('printhouse', {immediate: true})
    onDiemakerChange(printhouse: Printhouse) {
        if (printhouse) {
            this.name = printhouse.userProfile.name;
            this.email = printhouse.userProfile.email;
            this.phone = printhouse.userProfile.phone;
            this.language = printhouse.userProfile.locale;

            if (!this.language) {
                if (!this.isSuperadmin) {
                    this.language = this.$store.getters['auth/languageWithDefault'];
                }
            }
        }
    }

    private validate(): boolean {
        return (this.$refs.form as any).validate();
    }
}
