import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import BaseValidationInput from '@shared/validation/base-validation-input.component';
import { UtilService } from '@shared/utils/util.service';

@Component({
  inheritAttrs: false
})
export default class ColorPicker extends BaseValidationInput {

  @Prop({default: null}) label!: string;
  @Prop({default: '#edededff'}) value!: string;
  @Prop({
    default: () => {
      return `input-${UtilService.getUniqueNum()}`;
    }
  }) name!: string;
  @Prop({default: 'col-6'}) labelClass!: string;
  @Prop({default: 'col-6'}) inputClass!: string;

  menuVisible = false;

  handleChange(value: string) {
    this.$emit('input', value);
  }

}
