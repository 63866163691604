import { Component, Prop } from 'vue-property-decorator';
import { UtilService } from '../../utils/util.service';
import BaseValidationInput from '../../validation/base-validation-input.component';

@Component({
    inheritAttrs: false
})
export default class TextAreaField extends BaseValidationInput {
    @Prop({default: ''}) label!: string;
    @Prop({default: ''}) value!: string;
    @Prop({default: true}) labelOutside!: boolean;
    @Prop({
        default: () => {
            return `input-${UtilService.getUniqueNum()}`;
        }
    }) name!: string;
    @Prop({default: 1500}) maxLength!: number;

    handleChange(value: string) {
        this.$emit('input', value);
    }
}
