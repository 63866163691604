import { Component, Vue } from 'vue-property-decorator';
import { UtilService } from '@shared/utils/util.service';
import { Routes } from '../../../../routes.enum';
import { CustomerService } from '../../customer.service';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import Pagination from '@shared/components/pagination/pagination.component.vue';
import { CustomerListItem } from '../../models/customer-list-item.model';
import { Validators } from '@shared/validation/validators';

@Component({
    components: {
        Breadcrumbs,
        Pagination,
    }
})
export default class CustomerList extends Vue {
    readonly Routes = Routes;
    readonly Validators = Validators;
    breadcrumbs: BreadcrumbHistoryItem[] = [];
    searchLabel = this.$i18n.t('common.search');
    search = '';
    tableLoading = true;
    page = 1;
    itemsPerPage = 20;
    totalPages = 0;
    customers: CustomerListItem[] = [];
    totalCount = 0;
    tableHeaders = [
        {
            text: this.$i18n.t('customers.customerList.table.companyName'),
            value: 'companyName',
        },
        {
            text: 'Full name',
            value: 'fullName',
        },
        {
            text: 'Email',
            value: 'email',
        },
        {
            text: 'Phone',
            value: 'phone',
            width: '200px'
        },
    ];

    created() {
        this.initBreadcrumbs();

        if (!UtilService.isEmptyOrNull(this.$route.query.page)) {
            this.page = +this.$route.query.page;
        }

        if (!UtilService.isEmptyOrNull(this.$route.query.size)) {
            this.itemsPerPage = +this.$route.query.size;
        }

        if (!UtilService.isEmptyOrNull(this.$route.query.name)) {
            this.search = this.$route.query.name as string;
        }

        this.getCustomers();
    }

    async getCustomers() {
        const data = this.getSearchData();
        this.tableLoading = true;
        const {customers, totalCount, pageSize, startPage, totalNumPages} = await CustomerService.getCustomers(this.page, 10, data);
        this.tableLoading = false;
        this.customers = customers;
        this.totalCount = totalCount;
        this.itemsPerPage = pageSize;
        this.page = startPage + 1;
        this.totalPages = totalNumPages;
    }

    goToCustomerDetails(customer: CustomerListItem) {
        this.$router.push({name: Routes.CustomerDetails, params: {id: customer.email}});
    }

    updateSearch() {
        this.getCustomers();
        this.updateUrl();
    }

    onPageChange(page: number) {
        this.page = page;
        this.updateSearch();
    }

    private updateUrl() {
        this.$router.replace({name: Routes.CustomerList, query: this.getSearchData() as any});
    }

    private getSearchData() {
        const data: any = {
            page: this.page,
            size: this.itemsPerPage,
        };

        if (!UtilService.isEmptyOrNull(this.search)) {
            data.keywords = this.search;
        }

        return data;
    }

    private initBreadcrumbs() {
        this.breadcrumbs = [
            {
                text: 'customers.breadcrumbs.customers',
                disabled: false
            }
        ];
    }
}
