import { RequestService } from '@shared/request/request.service';
import { Config } from '../../../config';
import { DiemakerThemeRequest } from '../models/diemaker-theme.request';
import { Printhouse } from '../models/printhouse.model';

export class PrinthouseService {

    static getPrinthouse(): Promise<Printhouse> {
        return RequestService.get('api/v1/printhouse', null, { apiPath: Config.apiPortal }).then(r => new Printhouse(r));
    }

    static updateTheme(request: DiemakerThemeRequest) {
        return RequestService.post('api/v1/printhouse/theme', request, { apiPath: Config.apiPortal });
    }

    static deleteLogo() {
        return RequestService.delete('api/v1/printhouse/delete-logo', { apiPath: Config.apiPortal });

    }
}
