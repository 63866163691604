import { Component, Prop, Vue } from 'vue-property-decorator';
import { Config } from '../../config';
import { TranslateResult } from 'vue-i18n';
import BaseHeader from '@shared/components/base-header/base-header.component.vue';
import { Routes } from '../../routes.enum';

@Component({
  components: {
    BaseHeader
  }
})
export default class Header extends Vue {

  theme = Config.theme;

  readonly Route = Routes;

  get items(): Array<{tab: TranslateResult; path: string}> {
    const tabs: any[] = [];

    if (this.isLogged) {
      tabs.push({ tab: this.$i18n.t('header.orders'), name: Routes.OrderRoot });
      tabs.push({ tab: this.$i18n.t('header.customers'), name: Routes.CustomerRoot  });
    }

    return tabs;
  }

  get isLogged(): boolean {
    return this.$store.getters['auth/isAuthenticated'];
  }

    get logoUrl(): string {
        return this.$store.getters.printhouse?.logoUrl;
    }

  get isSuperadmin(): boolean {
    return this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/organizationUuid'];
  }

  goToSuperadmin() {
    this.$store.dispatch('auth/revertWorkAs');
  }

}
