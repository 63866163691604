import { Component, Vue, Watch } from 'vue-property-decorator';

import CustomImageInput from '@shared/components/custom-image-input/custom-image-input.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import SelectField from '@shared/components/select-field/select-field.component.vue';
import { RadioFieldItem } from '@shared/components/radio-field/radio-field-item.interface';
import RadioField from '@shared/components/radio-field/radio-field.component.vue';
import TextAreaField from '@shared/components/textarea-field/textarea-field.component.vue';
import { ServerError } from '@shared/request/server-error.model';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import { Validators } from '@shared/validation/validators';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { Printhouse } from '../../models/printhouse.model';
import { PrinthouseDetailsRequest } from '../../models/printhouse-details.request';
import { PrinthouseService } from '../../services/printhouse.service';

@Component({
    components: {
        CustomInput,
        CustomImageInput,
        LoadingButton,
        SelectField,
        RadioField,
        TextAreaField,
        FormErrorMessage
    }
})
export default class ProfileCompanyDetails extends Vue {

    readonly Validators = Validators;

    file: File | null = null;
    name = '';
    website = '';
    unit = Units.MM;
    units: RadioFieldItem[] = [];
    errorMessage: string | null = null;
    initialImage: string | null = null;

    async created() {
        this.units = [
            {value: Units.MM, text: Units.MM},
            {value: Units.INCH, text: Units.INCH},
           /* {value: Units.BOTH, text: this.$i18n.t('units.both') as string}*/
        ];
    }

    async updateDetails(stopLoading: FunctionConstructor) {

        this.errorMessage = null;

        if (this.validate()) {
            const data = {
                name: this.name,
                measurementUnit: this.unit,
            } as PrinthouseDetailsRequest;

            if (this.file) {
                data.logo = this.file;
            }

            try {
                await this.$store.dispatch('updateCompanyDetails', data);
                this.$store.dispatch('notification/showSuccess',
                    {text: this.$i18n.t('messages.saved')});
                if (this.file) {
                    this.initialImage = this.printhouse.logoUrl;
                }
            } catch (e: any | ServerError) {
                this.errorMessage = `errors.${e.code}`;
            }

        }

        stopLoading();
    }

    get printhouse() {
        return this.$store.getters.printhouse;
    }

    onFileDelete() {
        if (this.printhouse.logoUrl) {
            this.$store.dispatch('deleteLogo');
            this.initialImage = null;
        }
    }

    @Watch('printhouse', {immediate: true})
    onDiemakerChange(printhouse: Printhouse) {
        if (printhouse) {
            this.name = printhouse.name || '';
            this.unit = printhouse.units;
            this.initialImage = printhouse.logoUrl;
        }
    }

    private validate(): boolean {
        return (this.$refs.form as any).validate();
    }
}
