import { Component, Vue, Watch } from 'vue-property-decorator';
import { Routes } from '../../../../routes.enum';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { UtilService } from '@shared/utils/util.service';

@Component({
    components: {
        FormErrorMessage,
        CustomInput,
        LoadingButton
    }
})
export default class UserLogin extends Vue {

    username = '';
    password = '';
    isError = false;
    errorMessage = '';
    readonly Routes = Routes;

    async submit(stopLoading: () => void) {
        this.isError = false;

        if (this.validate()) {
            try {
                await this.$store.dispatch('auth/login', {
                    username: this.username,
                    password: this.password
                });

                stopLoading();
                (this.$refs.form as any).reset();

                if (this.redirectTo) {
                    this.$router.push({path: this.redirectTo});
                    return;
                } else {
                    this.$router.push({name: Routes.OrderList});
                }

            } catch (e) {
                if (e.message !== 'redirect') {
                    this.isError = true;
                    this.errorMessage = `errors.${e.code}`;
                }
            }
            stopLoading();
        } else {
            stopLoading();
        }
    }

    get redirectTo(): string {
        return this.$route.query.redirectTo as string;
    }

    private validate() {
        return (this.$refs.form as any).validate();
    }
}
