import Vue from 'vue';
import Vuex from 'vuex';
import notificationModule from '@shared/components/notification/notification.store';
import authModule from '@shared/store/auth.store';
import { confirmModalModule, confirmModuleNamespace } from '@shared/components/confirm-modal/confirm-modal.store';
import { DiemakerThemeRequest } from '../modules/profile/models/diemaker-theme.request';
import { Person } from '../modules/profile/models/person.model';
import { PersonService } from '../modules/profile/services/person.service';
import { Printhouse } from '../modules/profile/models/printhouse.model';
import { PrinthouseService } from '../modules/profile/services/printhouse.service';
import { PrinthouseDetailsRequest } from '../modules/profile/models/printhouse-details.request';
import { RequestService } from '@shared/request/request.service';
import { Config } from '../config';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        notification: notificationModule,
        [confirmModuleNamespace]: confirmModalModule,
        auth: authModule
    },
    state: {
        printhouse: null,
        groupsArray: [],
        drawingVisibleParams: [],
        previewOfCurrentProduct: {},
        chosenDrawing: '',
        isResizePage: false,
    },
    actions: {
        async getPrinthouse({state, commit}: any): Promise<Printhouse> {
            if (!state.printhouse) {
                const printhouse = await PrinthouseService.getPrinthouse();
                commit('setPrinthouse', printhouse);
            }
            return state.printhouse;
        },

        async deleteLogo({state, commit}: any) {
            PrinthouseService.deleteLogo();
            const printhouse = state.printhouse as Printhouse;
            printhouse.logoUrl = null;
            commit('setPrinthouse', printhouse);
        },

        async updateCompanyDetails({state, commit}: any, company: PrinthouseDetailsRequest): Promise<any> {
            const printhouse = state.printhouse as Printhouse;

            const request: any = {
                printhouse: JSON.stringify({
                    ...company,
                    logo: undefined,
                })
            };

            if (company.logo) {
                request.logo = company.logo;
            }

            await RequestService.put('api/v1/printhouse/update-printhouse', request, {multipart: true, apiPath: Config.apiPortal});

            printhouse.name = company.name;
            printhouse.units = company.measurementUnit;

            if (company.logo instanceof File) {
                const reader = new FileReader();

                reader.onload = e => {
                    if (e.target) {
                        printhouse.logoUrl = e.target.result as string;
                        commit('setPrinthouse', printhouse);
                    }
                };

                reader.readAsDataURL(company.logo);
            } else {
                commit('setPrinthouse', printhouse);
            }

        },
        async updateTheme({state, commit}: any, request: DiemakerThemeRequest): Promise<any> {
            await PrinthouseService.updateTheme(request);
            const printhouse = state.printhouse as Printhouse;
            printhouse.theme = request;
            commit('setPrinthouse', printhouse);

        },
        async updatePersonDetails({state, commit, getters}: any, person: Person): Promise<any> {
            const resp = await PersonService.update(person.guid, person);

            if (!getters['auth/organizationUuid']) {
                const loggedUser = getters['auth/loggedUser'];
                loggedUser.user.name = person.name;
                loggedUser.user.email = person.email;
                loggedUser.user.phone = person.phone;
                loggedUser.user.locale = person.locale;

                state.printhouse.userProfile.name = person.name;
                state.printhouse.userProfile.email = person.email;
                state.printhouse.userProfile.phone = person.phone;
                state.printhouse.userProfile.locale = person.locale;

                commit('auth/updateLoggedUser', loggedUser);
            }

            return resp;
        },
    },
    mutations: {
        setPrinthouse(state: any, printhouse: Printhouse) {
            state.printhouse = printhouse;
        },
    },
    getters: {
        printhouse: (state: any): Printhouse => {
            return state.printhouse;
        }
    }
});
