export class Person {
    name: string;
    email: string;
    phone: string;
    locale: string;
    guid: string;

    constructor({name, locale, email, phone, guid}: any) {
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.locale = locale;
        this.guid = guid;
    }
}
