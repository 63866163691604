import { Component, Vue } from 'vue-property-decorator';
import { CustomerService } from '../../customer.service';
import ContentField from '@shared/components/content-field/content-field.component.vue';

@Component({
    components: {
        ContentField
    }
})
export default class CustomerDetails extends Vue {
    companyName: string | null = null;
    email: string | null =  null;
    phone: string | null =  null;
    fullName: string | null =  null;

    async created() {
        const customer = await CustomerService.getCustomer(this.$route.params['id']);
        this.companyName = customer.companyName;
        this.fullName = customer.fullName;
        this.email = customer.email;
        this.phone = customer.phone;
    }
}
