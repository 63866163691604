import { RequestService } from '@shared/request/request.service';
import { Config } from '../../../config';
import { Person } from '../models/person.model';

export class PersonService {
    
    static update(userId: string, person: Person): Promise<any> {
        const data = {
            name: person.name,
            email: person.email,
            phone: person.phone,
            username: person.email,
            guid: userId,
            locale: person.locale
        };
        return RequestService.put('api/v1/user/update', data, { apiPath: Config.apiPortal });
    }
}
