import { Component, Vue, Watch } from 'vue-property-decorator';

import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import { DiemakerThemeRequest } from '../../models/diemaker-theme.request';
import { RadioFieldItem } from '@shared/components/radio-field/radio-field-item.interface';
import { ThemeBorderShadowType } from '@shared/enums/theme-border-shadow-type.enum';
import RadioField from '@shared/components/radio-field/radio-field.component.vue';
import ColorPicker from '@shared/components/color-picker/color-picker.component.vue';
import { Printhouse } from '../../models/printhouse.model';

@Component({
    components: {
        ColorPicker,
        LoadingButton,
        RadioField
    }
})
export default class ProfileTheme extends Vue {

    borderShadowTypes: RadioFieldItem[] = [
        {value: 'none', text: this.$i18n.t('profile.theme.borderShadowTypes.none') as string},
        {value: 'border', text: this.$i18n.t('profile.theme.borderShadowTypes.border') as string},
        {value: 'shadow', text: this.$i18n.t('profile.theme.borderShadowTypes.shadow') as string}
    ];
    borderShadowType: ThemeBorderShadowType = ThemeBorderShadowType.None;

    bgColor = '#1B2958';
    textColor = '#fff';
    buttonBgColor = '#4494e5';
    buttonTextColor = '#fff';
    linkColor = '#4494e5';
    borderShadowColor = '#ededed';

    readonly defaults = {
        bgColor: '#1B2958',
        textColor: '#ffffff',
        buttonBgColor: '#4494e5',
        buttonTextColor: '#ffffff',
        linkColor: '#4494e5',
        borderShadowType: ThemeBorderShadowType.None,
        borderShadowColor: '#ededed'
    };

    async apply(stopLoading: () => void) {
        const request = {
            headerFooterBkgColor: this.bgColor,
            headerFooterTextColor: this.textColor,
            buttonBkgColor: this.buttonBgColor,
            buttonTextColor: this.buttonTextColor,
            linkColor: this.linkColor,
            borderShadowType: this.borderShadowType,
        } as DiemakerThemeRequest;

        if (this.borderShadowType !== ThemeBorderShadowType.None) {
            request.borderShadowColor = this.borderShadowColor;
        } else {
            request.borderShadowColor = null;
        }

        await this.$store.dispatch('updateTheme', request);
        this.$store.dispatch('notification/showSuccess', {text: this.$i18n.t('messages.saved')});

        stopLoading();

    }

    reset() {
        this.bgColor = this.defaults.bgColor;
        this.textColor = this.defaults.textColor;
        this.buttonBgColor = this.defaults.buttonBgColor;
        this.buttonTextColor = this.defaults.buttonTextColor;
        this.linkColor = this.defaults.linkColor;
        this.borderShadowType = this.defaults.borderShadowType;
        this.borderShadowColor = this.defaults.borderShadowColor;
    }

    @Watch('borderShadowType')
    onBorderShadowTypeChange() {
        if (this.borderShadowType === ThemeBorderShadowType.None) {
            this.borderShadowColor = this.defaults.borderShadowColor;
        }
    }

    get printhouse(): Printhouse {
        return this.$store.getters.printhouse;
    }

    get headerStyle() {
        const styles: any = {
            'background-color': this.bgColor,
            'color': this.textColor,
        };

        if (this.borderShadowType === ThemeBorderShadowType.Border) {
            styles['border-bottom'] = `3px solid ${this.borderShadowColor}`;
        } else if (this.borderShadowType === ThemeBorderShadowType.Shadow) {
            styles['box-shadow'] = `0 5px 10px ${this.borderShadowColor}`;
        }

        return styles;
    }

    @Watch('printhouse', {immediate: true})
    onDiemakerChange(printhouse: Printhouse) {
        if (printhouse) {
            this.bgColor = printhouse.theme.headerFooterBkgColor || this.defaults.bgColor;
            this.textColor = printhouse.theme.headerFooterTextColor || this.defaults.textColor;
            this.buttonBgColor = printhouse.theme.buttonBkgColor || this.defaults.buttonBgColor;
            this.buttonTextColor = printhouse.theme.buttonTextColor || this.defaults.buttonTextColor;
            this.linkColor = printhouse.theme.linkColor || this.defaults.linkColor;
            this.borderShadowType = printhouse.theme.borderShadowType || this.defaults.borderShadowType;
            this.borderShadowColor = printhouse.theme.borderShadowColor || this.defaults.borderShadowColor;
        }
    }
}
